
.tools {
  list-style: disc;

  li {
    margin-left: 3rem;
  }
}

.social {
  li {
    margin: 1rem;
  }

  i {
    padding-right: .5rem;
  }
}