$white: #fff;
$gray-100: #fafafa;
$gray-150: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-850: #333333;
$gray-900: #212121;
$gray-950: #1c1c1c;
$black: #000;

$blue: #2196f3;
$blue-light-1: #42a5f5;
$blue-light-2: #64b5f6;
$blue-light-3: #90caf9;
$blue-light-4: #bbdefb;
$blue-light-5: #e3f2fd;
$blue-dark-1: #1e88e5;
$blue-dark-2: #1976d2;
$blue-dark-3: #1565c0;
$blue-dark-4: #0d47a1;
$blue-dark-5: #083a88;

$primary: #93748a;
$secondary: #AF3B6E;

$colors: (
        "black":      $black,
        "white":      $white,
        "gray":       $gray-600,
        "gray-dark":  $gray-800,
        "primary": $primary,
        "secondary": $secondary,
);

$blues: (
  'light-1': $blue-light-1,
  'light-2': $blue-light-2,
  'light-3': $blue-light-3,
  'light-4': $blue-light-4,
  'light-5': $blue-light-5,
  'dark-1': $blue-dark-1,
  'dark-2': $blue-dark-2,
  'dark-3': $blue-dark-3,
  'dark-4': $blue-dark-4,
  'dark-5': $blue-dark-5,
);

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * .5,
        2: $spacer * 1,
        3: $spacer * 1.5,
        4: $spacer * 2,
        5: $spacer * 3,
        6: $spacer * 4,
        7: $spacer * 5,
);


//$headings-margin-bottom: 0;

$font-size-base: 1rem;
$line-height-base: 1.5;

$font-size: 1.6rem;
$font-size-xxs: .8rem;
$font-size-xs: 1rem;
$font-size-sm: 1.2rem;
$font-size-lg: 2rem;
$font-size-xl: 2.4rem;
$font-size-xxl: 3rem;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$shadow: 0 8px 17px 2px rgba(0,0,0,0.14),
  0 3px 14px 2px rgba(0,0,0,0.12),
0 5px 5px -3px rgba(0,0,0,0.2);


$disableOpacity: 0.6;

$border-radius: 0.4rem;

// Buttons
$button-padding: 0 2rem;
$button-border: 1px solid;


/*
    Custom Theme Vars
 */

$headerHeight: 8rem;


$icon-plane: '\f072';

// Shadows
$shadow-1: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
$shadow-2: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
$shadow-3: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
$shadow-4: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
$shadow-5: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);