
.section-work-history {

  .text-heading {
    text-align: center;
    font-size: 1.5rem;
  }

  .text-subheading {
    color: black;
    font-family: "Roboto Slab";
    font-weight: 500;
    font-size: 2.5rem;
  }
}