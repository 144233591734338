
.section-banner {
  min-height: 300px;
  text-align: center;

  h2 {
    font-family: 'Roboto Slab';
    font-size: 6rem;
    max-width: 600px;
    font-weight: 700;
    margin: auto;
  }

  p {
    font-size: 4rem;
    font-weight: 700;
  }

  span {
    vertical-align: middle;
  }

  .collage {
    background: url('../../assets/img/bali.jpg') center 0 no-repeat;
    background-size: cover;
  }
}

